import * as Sentry from "@sentry/nuxt";

const { sentry, ENV } = useRuntimeConfig().public;
const router = useRouter();

Sentry.init({
  enabled: ENV === "production" || ENV === "staging",
  dsn: sentry.dsnFront,
  environment: ENV,
  integrations: [
    // used to track the performance of the application
    Sentry.browserTracingIntegration({ router }),
    // replays are video recordings of user sessions
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maxReplayDuration: 20,
    }),
  ],
  maxBreadcrumbs: 2, // Changed from default value 100 to 2 because we were dropping too many events
  maxValueLength: 250, // this is default value

  // These values need to be between 0.0 and 1.0 and represent the percentage of traces to capture
  // It's how much we sample from all events
  tracesSampleRate: 1.0,
  // we don't want to capture whole sessions across all users
  replaysSessionSampleRate: 0.5,
  // but we want to capture all errors
  replaysOnErrorSampleRate: 1,

  beforeSend(event, hint) {
    // hint is an object that contains the original exception and the reason why the event was captured
    // This is a custom fingerprint for Zod validation errors - a fingerprint is like a tag that can be used to group similar events
    const error = hint.originalException as any;
    const zodRegex = /(requis|n'est pas valide|ZodError|Veuillez remplir tous les champs)/g;
    if (error && error.message && error.message.match(zodRegex)) {
      event.fingerprint = ["zod-validation-error"];
      event.tags = { ...event.tags, errorType: "ZodValidationError" };
    }
    return event;
  },
});
